import React, { useContext, useEffect } from 'react'
import ColorContext from '../context/ColorContext'
import { breakpoints, colors, hexToRGB } from "../theme/themeAlopex"
import { H2 } from '../theme/Headings'
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {
  Box,
  Grid,
  Container,
} from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'


const HubSpot = ({ embedURL, superTitle, title, blurb }) => {
  const { color } = useContext(ColorContext)
  
  // HubSpot Embed Code
  const addExternalScript = (url) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
  };
  useEffect(()=>{
    addExternalScript("https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js")
  },[])

  return (
    <Box
      as="section"
      backgroundColor={colors.brand.slate}
      pt={['84px', null, '184px']}
      pb={[null, null, '118px']}
      position="relative"
      sx={{
        '.talentPhoto': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          bottom: 0,
          right: 0,
          mixBlendMode: 'soft-light'
        },
      }}
    >
      <Box
        className="talentPhoto"
        backgroundImage={`url(/images/alopex-office.webp)`}
        backgroundSize="cover"
      />
      <Box
        position="absolute"
        inset="0"
        backgroundImage={`linear-gradient(170deg, rgba(255, 255, 255, 0), ${hexToRGB(color.bravo, 0.25)}), linear-gradient(225deg, ${hexToRGB(colors.brand.slate, 0.01)}, ${hexToRGB(color.alpha, 0.7)} 75%)`}
      />
      <Container maxWidth={breakpoints['2xl']} position="relative">
        <Grid gridTemplateColumns={[null, null, '1.5fr 1fr']} gridGap="4rem">
          <div className="meetings-iframe-container" data-src={embedURL}></div>
          <Box order={['-1', null, '2']} display="flex" flexDirection="column" justifyContent="center" color="white">
            <H2 variant="figtreeSm" opacity="0.5">{superTitle}</H2>
            <H2 variant="figtree">{title}</H2>
            <ReactMarkdown remarkPlugins={[gfm]} children={blurb} />
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default HubSpot