import React, { useEffect, useContext, useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box } from '@chakra-ui/react'
import { colors, hexToRGB } from '../theme/themeAlopex'
import ColorContext from '../context/ColorContext'
import BackgroundImage from 'gatsby-background-image'

const Marquee = ({
  direction = 'left',
  marqWidth = 100,
  marqHeight = '120px',
  displayed = 6,
  marqDur = 6,
}) => {
  const [marqElementsDisplayed, setMarqElementsDisplayed] = useState(displayed)
  const [marqElementWidth, setMarqElementWidth] = useState(marqWidth / displayed)
  const { color } = useContext(ColorContext)
  const refMarquee = useRef()
  const refMarqueeContent = useRef()

  // Shuffle
  function shuffle(array) {
    let i = array.length
    let j = 0
    let temp
    while (i--) {
        j = Math.floor(Math.random() * (i+1))
        // swap randomly chosen element with current element
        temp = array[i]
        array[i] = array[j]
        array[j] = temp
    }
    return array
  }

  // Prepare Clients
  const { allMarqueeJson, allTalentJson } = useStaticQuery(
    graphql`
      query {
        allMarqueeJson {
          nodes {
            stat
            icon
            blurb
          }
        }
        allTalentJson(sort: {fields: order, order: ASC}) {
          nodes {
            fName
            lName
            position
            roles
            photo
            order
            fields {
              imageRel {
                childImageSharp {
                  fluid(maxWidth: 512, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  let marqArray = JSON.parse(JSON.stringify(allMarqueeJson.nodes))
  allTalentJson.nodes.map((t, i) => {
    marqArray.push({
      stat: '__TALENT',
      icon: t.fields.imageRel.childImageSharp.fluid,
      blurb: `${t.fName}<br /><small>${t.position}</small>`,
    })
  })
  marqArray = shuffle(marqArray)

  
  const marqElements = allMarqueeJson.nodes.length
  // const marqElementWidth = marqWidth / marqElementsDisplayed
  const marqAnimDuration = `${marqElements * marqDur}s`
  // console.log(marqWidth, marqHeight, marqElementsDisplayed, marqDur, marqElements, marqElementWidth, marqAnimDuration)

  useEffect(() => {
    for (let i = 0; i < marqElementsDisplayed; i++) {
      refMarqueeContent.current.appendChild(refMarqueeContent.current.children[i].cloneNode(true))
    }
  }, [])
  
  const updateMedia = () => {
    if (window.innerWidth >= 960) {
      setMarqElementsDisplayed(displayed)
      setMarqElementWidth(marqWidth / displayed)
    } else if (window.innerWidth < 960 && window.innerWidth >= 640) {
      setMarqElementsDisplayed(4)
      setMarqElementWidth(marqWidth / 4)
    } else if (window.innerWidth < 640) {
      setMarqElementsDisplayed(2)
      setMarqElementWidth(marqWidth / 2)
    }
  };
  useEffect(() => {
    updateMedia()
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <Box
      ref={refMarquee}
      className="marquee"
      sx={{
        // '--marquee-width': '100vw',
        // '--marquee-height': '20vh',
        // '--marquee-elements-displayed': '6',
        // '--marquee-element-width': 'calc(var(--marquee-width) / var(--marquee-elements-displayed))',
        // '--marquee-animation-duration': 'calc(var(--marquee-elements) * 3s)',
        width: `${marqWidth}%`,
        height: marqHeight,
        background: `linear-gradient(to right, ${hexToRGB(color.alpha, 0.35)}, ${hexToRGB(color.alpha, 0.05)})`,
        overflow: 'hidden',
        position: 'relative',
        '.marquee-content': {
          listStyle: 'none',
          height: '100%',
          display: 'flex',
          animation: `scrolling ${marqAnimDuration} linear infinite`,
          animationDirection: direction === 'left' ? 'normal' : 'reverse',
          marginBottom: 0,
          /* '&:hover': {
            animationPlayState: 'paused'
          }, */
          'li': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexDirection: 'column',
            flexShrink: 0,
            width: `${marqElementWidth}%`,
            maxHeight: '100%',
            borderRight: `1px solid ${hexToRGB(colors.brand.slate, 0.5)}`,
            padding: '1rem',
            lineHeight: '1',
            marginBottom: 0,
            textAlign: 'center',
            fontFamily: 'Abel',
            '& .stat': {
              fontSize: '38px',
              fontWeight: '300',
              height: '56px',
              lineHeight: '0.7',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              img: {
                maxWidth: '44px',
                maxHeight: '44px',
                width: '100%'
              }
            },
            span: {
              fontSize: '1rem'
            }
          }
        },
        '@keyframes scrolling': {
          '0%': { transform: 'translateX(0%)' },
          '100%': { transform: `translateX(${-1 * marqElementWidth * marqElements}%)`}
        }
      }}
    
    >
      <ul ref={refMarqueeContent} className="marquee-content">
        {marqArray.length && marqArray.map((marq, i) => {
          return (
            <>
            {marq?.stat !== '__TALENT' ? (
              <li key={`marq-key-${i}`}>
                <Box className="stat">
                  {!!marq?.icon ? (
                    <img src={marq.icon.replace('/static', '')} alt={marq?.stat} />
                  ) : (
                    <>{marq?.stat}</>
                  )}
                </Box>
                <span>{marq?.blurb}</span>
              </li>
            ) : (
              <BackgroundImage Tag="li" fluid={marq.icon}  key={`marq-key-${i}`} mixBlendMode="multiply" style={{justifyContent: 'flex-end'}}>
                <Box alignSelf="flex-start" textAlign="left" dangerouslySetInnerHTML={{__html: marq.blurb}} />
              </BackgroundImage>
            )}
            </>
          )
        })}
      </ul>
    </Box>
  )
}

export default Marquee